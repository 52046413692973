/*import Vue from 'vue'
import VueRouter from 'vue-router'*/
import store from './store'
// import loginRepository from './components/services/api/loginRepository'
import moment from 'moment'
//Vue.use(VueRouter)
import { createRouter, createWebHistory } from 'vue-router'

const Login = () => import('./components/views/login/Login.vue')
const DashboardLayout = () => import('./components/template/DashboardLayout.vue')
//const Agenda = () => import('./components/views/agenda/Agenda.vue')
const Empresas = () => import('./components/views/empresas/Empresas.vue')
const EmpresasCadastro = () => import('./components/views/empresas/EmpresasCadastro.vue')
const EmpresasCadastroUnidade = () => import('./components/views/empresas/EmpresasCadastroUnidade.vue')
const TreinamentosTipos = () => import('./components/views/treinamentos/tipos/TreinamentosTipos.vue')
const TreinamentosTiposCadastro = () => import('./components/views/treinamentos/tipos/TreinamentosTiposCadastro.vue')
const Treinamentos = () => import('./components/views/treinamentos/Treinamentos.vue')
const TreinamentosCadastro = () => import('./components/views/treinamentos/TreinamentosCadastro.vue')
const TreinamentoImpressao = () => import('./components/views/treinamentos/TreinamentoImpressao.vue')
const TreinamentosModelosCadastro = () => import('./components/views/treinamentos/treinamentoModelos/TreinamentosModelosCadastro.vue')
const TreinamentosModelos = () => import('./components/views/treinamentos/treinamentoModelos/TreinamentosModelos.vue')
const TreinamentosFuncionarios = () => import('./components/views/treinamentos/TreinamentosFuncionarios.vue')
const Funcionarios = () => import('./components/views/funcionarios/Funcionarios.vue')
const FuncionariosCadastro = () => import('./components/views/funcionarios/FuncionariosCadastro.vue')
const Configuracoes = () => import('./components/template/MobileMenuConfiguracoes.vue')
const Parametros = () => import('./components/views/configuracoes/Parametros.vue')
const FuncionariosCargos = () => import('./components/views/configuracoes/cargo/FuncionariosCargos.vue')
const FuncionariosCargosCadastro = () => import('./components/views/configuracoes/cargo/FuncionariosCargosCadastro.vue')
const Deficiencias = () => import('./components/views/configuracoes/deficiencia/Deficiencias.vue')
const DeficienciasCadastro = () => import('./components/views/configuracoes/deficiencia/DeficienciasCadastro.vue')
const Instrutores = () => import('./components/views/treinamentos/instrutores/Instrutores.vue')
const InstrutoresCadastro = () => import('./components/views/treinamentos/instrutores/InstrutoresCadastro.vue')
const LocaisTreinamentos = () => import('./components/views/treinamentos/localTreinamento/LocaisTreinamentos.vue')
const LocaisTreinamentosCadastro = () => import('./components/views/treinamentos/localTreinamento/LocaisTreinamentosCadastro.vue')
const LocaisTreinamentosSalas = () => import('./components/views/treinamentos/localTreinamento/sala/LocaisTreinamentosSalas.vue')
const LocaisTreinamentosSalasCadastro = () => import('./components/views/treinamentos/localTreinamento/sala/LocaisTreinamentosSalasCadastro.vue')
const TreinamentoCertificados = () => import('./components/views/treinamentos/certificado/TreinamentoCertificado.vue')
const TreinamentosFaltas = () => import('./components/views/treinamentos/TreinamentosFaltas.vue')

// NORMAS
const TreinamentoNormas = () => import('./components/views/treinamentos/normas/Normas.vue')
const TreinamentoNormasCadastro = () => import('./components/views/treinamentos/normas/NormasCadastro.vue')

const PerfilAcesso = () => import('./components/views/configuracoes/perfilAcesso/PerfilAcesso.vue')
const PerfilAcessoCadastro = () => import('./components/views/configuracoes/perfilAcesso/PerfilAcessoCadastro.vue')
const Permissoes = () => import('./components/views/configuracoes/perfilAcesso/permissoes/Permissoes.vue')
const Usuarios = () => import('./components/views/configuracoes/usuarios/Usuarios.vue')
const UsuariosCadastro = () => import('./components/views/configuracoes/usuarios/UsuariosCadastro.vue')

// RELATORIOS
const RelatoriosIndex = () => import('./components/views/relatorios/Relatorios.vue')
const RelTreinamentoFaltas = () => import('./components/views/relatorios/treinamento/RelTreinamentoFaltas.vue')
const RelTreinamento = () => import('./components/views/relatorios/treinamento/RelTreinamento.vue')
const RelTreinamentoVencimento = () => import('./components/views/relatorios/treinamento/RelTreinamentoVencimento.vue')
const RelCredenciaisFuncionarios = () => import('./components/views/relatorios/RelCredenciaisFuncionarios.vue')
const RelEmpresaUnidadeDocumentos = () => import('./components/views/relatorios/RelEmpresaUnidadeDocumentos.vue')

const routes = [
  {
    path: '/',
    component: Login,
    redirect: 'admin/login'
  },
  {
    path: '/admin/login',
    component: Login,
    name: 'AdminLogin'
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/empresas',
    meta: { requiresLogin: true },
    children: [
      /*{
        path: 'agenda',
        name: 'Agenda',
        component: Agenda
      },*/
      {
        path: 'empresas',
        name: 'Empresas',
        component: Empresas
      },
      {
        path: 'empresas/cadastro/:id?',
        name: 'EmpresasCadastro',
        component: EmpresasCadastro
      },
      {
        path: 'empresas/unidade/cadastro/:id?',
        name: 'EmpresasCadastroUnidade',
        component: EmpresasCadastroUnidade
      },
      {
        path: 'treinamentos',
        name: 'Treinamentos',
        component: Treinamentos
      },
      {
        path:'treinamentos/cadastro',
        name: 'TreinamentosCadastro',
        component: TreinamentosCadastro
      },
      {
        path:'treinamentos/impressao',
        name: 'TreinamentoImpressao',
        component: TreinamentoImpressao
      },
      {
        path:'treinamentos/modelos',
        name: 'TreinamentosModelos',
        component: TreinamentosModelos
      },
      {
        path:'treinamentos/modelos/cadastro',
        name: 'TreinamentosModelosCadastro',
        component: TreinamentosModelosCadastro
      },
      {
        path:'treinamentos/funcionarios',
        name: 'TreinamentosFuncionarios',
        component: TreinamentosFuncionarios
      },
      {
        path: 'funcionarios',
        name: 'Funcionarios',
        component: Funcionarios
      },
      {
        path: 'funcionarios/cadastro',
        name: 'FuncionariosCadastro',
        component: FuncionariosCadastro
      },
      {
        path: 'configuracoes',
        name: 'Configuracoes',
        component: Configuracoes
      },
      {
        path: 'configuracoes/parametros',
        name: 'Parametros',
        component: Parametros
      },
      {
        path: 'configuracoes/usuarios',
        name: 'Usuarios',
        component: Usuarios
      },
      {
        path: 'configuracoes/usuarios/cadastro',
        name: 'UsuariosCadastro',
        component: UsuariosCadastro
      },
      {
        path: 'configuracoes/cargos',
        name: 'FuncionariosCargos',
        component: FuncionariosCargos
      },
      {
        path: 'configuracoes/cargos/cadastro',
        name: 'FuncionariosCargosCadastro',
        component: FuncionariosCargosCadastro
      },
      {
        path: 'configuracoes/deficiencias',
        name: 'Deficiencias',
        component: Deficiencias
      },
      {
        path: 'configuracoes/deficiencias/cadastro',
        name: 'DeficienciasCadastro',
        component: DeficienciasCadastro
      },
      {
        path: 'treinamentos/instrutores',
        name: 'Instrutores',
        component: Instrutores
      },
      {
        path: 'treinamentos/instrutores/cadastro',
        name: 'InstrutoresCadastro',
        component: InstrutoresCadastro
      },
      // locais treinamento
      {
        path: 'treinamentos/locais',
        name: 'LocaisTreinamentos',
        component: LocaisTreinamentos
      },
      {
        path: 'treinamentos/locais/cadastro',
        name: 'LocaisTreinamentosCadastro',
        component: LocaisTreinamentosCadastro
      },
      // salas locais de treinamento
      {
        path: 'treinamentos/locais/salas',
        name: 'LocaisTreinamentosSalas',
        component: LocaisTreinamentosSalas
      },
      {
        path: 'treinamentos/locais/salas/cadastro',
        name: 'LocaisTreinamentosSalasCadastro',
        component: LocaisTreinamentosSalasCadastro
      },
      // tipos de treinamento (tipos de evento)
      {
        path: 'treinamentos/tipos',
        name: 'TreinamentosTipo',
        component: TreinamentosTipos
      },
      {
        path: 'treinamentos/tipos/cadastro',
        name: 'TreinamentosTiposCadastro',
        component: TreinamentosTiposCadastro
      },
      // NORMAS
      {
        path: 'treinamentos/normas',
        name: 'TreinamentoNormas',
        component: TreinamentoNormas
      },
      {
        path: 'treinamentos/normas/cadastro',
        name: 'TreinamentoNormasCadastro',
        component: TreinamentoNormasCadastro
      },
      // certificados
      {
        path: 'treinamentos/certificados',
        name: 'TreinamentoCertificados',
        component: TreinamentoCertificados
      },
      {
        path: 'configuracoes/perfilAcesso',
        name: 'PerfilAcesso',
        component: PerfilAcesso
      },
      {
        path: 'configuracoes/perfilAcesso/cadastro',
        name: 'PerfilAcessoCadastro',
        component: PerfilAcessoCadastro
      },
      {
        path: 'configuracoes/perfilAcesso/permissao',
        name: 'Permissoes',
        component: Permissoes
      },
      {
        path: 'treinamentos/faltas',
        name: 'TreinamentosFaltas',
        component: TreinamentosFaltas
      },
      {
        path: 'relatorios',
        name: 'RelatoriosIndex',
        component: RelatoriosIndex
      },
      {
        path: 'relatorios/treinamento/faltas',
        name: 'RelTreinamentoFaltas',
        component: RelTreinamentoFaltas
      },
      {
        path: 'relatorios/treinamento',
        name: 'RelTreinamento',
        component: RelTreinamento
      },
      {
        path: 'relatorios/treinamento/vencidos',
        name: 'RelTreinamentoVencimento',
        component: RelTreinamentoVencimento
      },
      {
        path: 'relatorios/funcionarios/credencial',
        name: 'RelCredenciaisFuncionarios',
        component: RelCredenciaisFuncionarios
      },
      {
        path: 'relatorios/empresa-unidade/documentos',
        name: 'RelEmpresaUnidadeDocumentos',
        component: RelEmpresaUnidadeDocumentos
      },
    ]
  }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/AdminDashboard/' + name + '.vue');
   return res;
};**/

// configure router
/*const router = new VueRouter({
  routes,
  mode: 'history'
})*/
const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let dateLogin = false
  if (store.getters.ApiToken != null && store.getters.getLastTimeLogged != '') {
    let date1 = moment(store.getters.getLastTimeLogged).add(1, 'hours').format("yyyy-MM-DD HH:mm:ss")
    let date2 = moment(new Date()).format("yyyy-MM-DD HH:mm:ss")

    dateLogin = date1 < date2
  }

  if (to.matched.some(record => record.meta.requiresLogin) && ( store.getters.ApiToken == null || dateLogin)) {

    store.commit('SET_LOGOUT')
    next('/admin/login')
  } else if (store.getters.ApiToken != null && to.path == '/admin/login') {

    next('/admin/treinamentos')
  } else {
    next()
  }
})




export default router
