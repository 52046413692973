//import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import moment from 'moment'

//Vue.use(Vuex)

export default Vuex.createStore({
  state: {
    user: null,
    userPermission: [],
    lastTimeLogged: '',
    apiToken: null,
    splashScreen: false
  },
  mutations: {
    setSplashScreen(state, payload) {
			state.splashScreen = payload
		},
    SET_LOGIN (state, obj) {
      state.user = obj
    },
    SET_PERMISSION (state, permissoes) {
      state.userPermission = permissoes
    },
    SET_LOGOUT (state) {
      state.user = null
      state.apiToken = null
      state.userPermission = []
    },
    SET_TIME_LOGIN (state) {
      let data = new moment()
      state.lastTimeLogged = data
    },
    SET_API_TOKEN (state, token) {
      state.apiToken = token
    }
  },
  getters: {
    splashScreen: state => state.splashScreen,
    permissoes: state => state.userPermission,
    LoggedUser: state => state.user,
    ApiToken: state => state.apiToken,
    getLastTimeLogged: state => state.lastTimeLogged
  },
  plugins: [createPersistedState()]
})
