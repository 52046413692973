//import Vue from 'vue'

/*
Vue.filter('maskdateptbr', function (value) {
  if (value === null || value === '') return ''
  return value.slice(0, 10).split('-').reverse().join('/')
})
*/
export default {
  maskdateptbr(value) {
    if (value === null || value === '') return ''
    return value.slice(0, 10).split('-').reverse().join('/')
  }
}